let lang = "ja"

const controlLang = (changeLang) => {
  lang = changeLang;

  // scrollButtonText
  document.getElementById("scrollButtonText").textContent = dafaultLanguage.scrollButtonText[lang];

  // philosophy.philosophy1
  document.getElementById("philosophy.philosophy1").textContent = dafaultLanguage.philosophy.philosophy1[lang];

  // philosophy.philosophy2
  document.getElementById("philosophy.philosophy2").textContent = dafaultLanguage.philosophy.philosophy2[lang];

  // // ceo.ceo1
  // document.getElementById("ceo.ceo1").textContent = dafaultLanguage.ceo.ceo1[lang];

  // // ceo.ceo2
  // document.getElementById("ceo.ceo2").textContent = dafaultLanguage.ceo.ceo2[lang];

  // // ceo.detailButton
  // document.getElementById("ceo.detailButton").textContent = dafaultLanguage.ceo.detailButton[lang];
  // document.getElementById("ceo.detailButton").href = dafaultLanguage.ceo.detailHref[lang];

  // business.business1
  document.getElementById("business.business1.title").textContent = dafaultLanguage.business.business1.title[lang];
  document.getElementById("business.business1.detail").textContent = dafaultLanguage.business.business1.detail[lang];

  // business.business2
  document.getElementById("business.business2.title").textContent = dafaultLanguage.business.business2.title[lang];
  document.getElementById("business.business2.detail").textContent = dafaultLanguage.business.business2.detail[lang];

  // business.business3
  document.getElementById("business.business3.title").textContent = dafaultLanguage.business.business3.title[lang];
  document.getElementById("business.business3.detail").textContent = dafaultLanguage.business.business3.detail[lang];

  // contact.description
  document.getElementById("contact.description").textContent = dafaultLanguage.contact.description[lang];
  document.getElementById("contact.buttonText").textContent = dafaultLanguage.contact.buttonText[lang];

  // company.name
  document.getElementById("company.name").textContent = dafaultLanguage.company.name[lang];

  // company.builtDate
  document.getElementById("company.builtDate").textContent = dafaultLanguage.company.builtDate[lang];

  // company.place
  document.getElementById("company.place").textContent = dafaultLanguage.company.place[lang];

  // company.content
  document.getElementById("company.content").textContent = dafaultLanguage.company.content[lang];

  // company.capital
  document.getElementById("company.capital").textContent = dafaultLanguage.company.capital[lang];

  // vision.description1
  document.getElementById("vision.description1").textContent = dafaultLanguage.vision.description1[lang];

  // vision.description2
  document.getElementById("vision.description2").textContent = dafaultLanguage.vision.description2[lang];

  // vision.button
  document.getElementById("vision.button").textContent = dafaultLanguage.vision.button[lang];

  console.log('/'  + dafaultLanguage.vision.image[lang])

  if(lang === 'ja') {
    document.getElementById("vision.image_ja").style.display = "";
    document.getElementById("vision.image_en").style.display = "none";
  } else {
    document.getElementById("vision.image_ja").style.display = "none";
    document.getElementById("vision.image_en").style.display = "";
  }
}